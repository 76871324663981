<template>
  <div>
    <!-- Gold Sponsors -->
    <h1 class="title is-3">In partnership with</h1>
    <div class="columns is-centered is-variable is-8">
      <div class="column is-narrow">
        <p class="heading is-size-4">Pearl Sponsors</p>
        <div class="columns is-centered is-vcentered is-multiline is-mobile is-variable is-5">
          <div class="column is-narrow" v-for="sponsor in pearl">
            <img :src="`https://cdn.statically.io/img/${sponsor.logo.replace(/(https:\/\/)|-/g, '')}`" class="logo pearl" @click="launchModal(sponsor)">
          </div>
        </div>
      </div>
      <div class="column is-narrow">
        <p class="heading is-size-4">Glitter Sponsors</p>
        <div class="columns is-centered is-vcentered is-multiline is-mobile is-variable is-5">
          <div class="column is-narrow" v-for="sponsor in glitter">
            <img :src="`https://cdn.statically.io/img/${sponsor.logo.replace(/(https:\/\/)|-/g, '')}`" class="logo glitter" @click="launchModal(sponsor)">
          </div>
        </div>
      </div>
    </div>

    <p class="heading is-size-5">Gold Sponsors</p>
    <div class="columns is-centered is-vcentered is-multiline is-mobile is-variable is-5">
      <div class="column is-narrow" v-for="sponsor in gold">
          <img :src="`https://cdn.statically.io/img/${sponsor.logo.replace(/(https:\/\/)|-/g, '')}`" class="logo gold" @click="launchModal(sponsor)">
      </div>
    </div>

    <p class="heading is-size-6">Silver Sponsors</p>
    <div class="columns is-centered is-vcentered is-multiline is-mobile">
      <div class="column is-narrow" v-for="sponsor in silver">
        <img :src="`https://cdn.statically.io/img/${sponsor.logo.replace(/(https:\/\/)|-/g, '')}`" class="logo silver" @click="launchModal(sponsor)">
      </div>
    </div>

    <div class="columns is-centered is-variable is-8">
      <div class="column is-narrow">
        <p class="heading is-size-7">Media Partners</p>
        <div class="columns is-centered is-vcentered is-multiline is-mobile">
          <div class="column is-narrow" v-for="sponsor in media">
            <img :src="`https://cdn.statically.io/img/${sponsor.logo.replace(/(https:\/\/)|-/g, '')}`" class="logo media" @click="launchModal(sponsor)">
          </div>
        </div>
      </div>
      <div class="column is-narrow">
        <p class="heading is-size-7">Partner Organizations</p>
        <div class="columns is-centered is-vcentered is-multiline is-mobile">
          <div class="column is-narrow" v-for="sponsor in orgs">
            <img :src="`https://cdn.statically.io/img/${sponsor.logo.replace(/(https:\/\/)|-/g, '')}`" class="logo orgs" @click="launchModal(sponsor)">
          </div>
        </div>
      </div>
    </div>

    <p class="has-text-grey is-size-7 is-italic">Click on each logo to learn more</p>

    <!-- Modal -->
    <b-modal v-if="sponsorModalContent" v-model="isSponsorModalActive" :width="640" @close="onModalClose">
      <div class="card">
        <div class="card-image" v-if="sponsorModalContent.avp">
          <figure class="image is-16by9 has-background-grey">
            <iframe :src="sponsorModalContent.avp" class="has-ratio"></iframe>
          </figure>
        </div>
        <div class="card-content">
          <div class="columns is-mobile is-vcentered">
            <div class="column is-one-quarter-tablet is-one-third-mobile">
              <img :src="`https://cdn.statically.io/img/${sponsorModalContent.logo.replace(/(https:\/\/)|-/g, '')}`">
            </div>
            <div class="column has-text-left">
              <p class="title has-text-primary-dark">{{sponsorModalContent.name}}</p>
              <a v-for="website in sponsorModalContent.website" class="subtitle is-6" :href="website" target="_blank">{{website}}<br></a>
            </div>
          </div>
        </div>
        <div class="content p-5 has-text-left" style="white-space: pre-wrap;">
          <p>{{sponsorModalContent.writeup}}</p>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "SponsorsList",
  data() { return {
    isSponsorModalActive: false,
    sponsorModalContent: null,
    pearl: [
      {
        name: "Clorox International Philippines",
        avp: "https://drive.google.com/file/d/1YRYDbdZuAf7cNaZ15gH9HYfWhCCI70Qu/preview",
        logo: "https://i.ibb.co/NWFW6tP/clorox.png"
      }
    ],
    glitter: [
      {
        name: "Glambot",
        logo: "https://i.ibb.co/LZ195pH/glambot.jpg"
      }
    ],
    gold: [
      {
        name: "Manea Construction Corporation",
        logo: "https://i.ibb.co/KXKCjLR/1-Manea-Logo-min.png",
        writeup: "Manea Construction Corporation is a domestic corporation duly organized and existing under and by virtue of the laws of the Republic of the Philippines with office address at 123 Soliven Street, Greenheights Subdivision Phase 1, Concepcion Uno, Marikina City. It was duly registered with the Securities and Exchange Commission under SEC Registration No. CS202007520.\n" +
            "\n" +
            "We engage in general construction business including the constructing, enlarging, repairing, developing or otherwise engaging in any work upon building, houses, roads, parks, waterworks and other structures.\n" +
            "\n" +
            "We aim to develop, manage, buy and sell, lease/sub-lease or rental of land, building, office units and houses. As well as, providing rental services (virtual office) for business entities and others.\n" +
            "\n" +
            "We offer trucking and hauling services, performing transport of goods and merchandise from one particular point to its destination such as hauling and rental of industrial equipment. \n" +
            "\n" +
            "We also offer trading of goods such as, but not limited to hardware, electrical, paints, plumbing, construction supplies and aggregates; tools, industrial and heavy equipment. \n" +
            "\n" +
            "Our company is supported by technical and administrative personnel whose competence and integrity in their respective fields are beyond question. Their loyalty to the company and sincerity in the exercise of their profession contributed immensely in the attainment of every endeavor the company undertakes.\n"
      },
      {
        name: "Dropitea Marikina",
        logo: "https://i.ibb.co/5rJZ8nX/dropitea-min.png",
        writeup: "Dropitea Marikina is a local brand of milktea located at the Marikina Sports Center. This brand evolved in Marikina as it ventured into crafting its own menu for frappes, takoyaki, and other snacks. The aim of this brand is to give Marikeños a more affordable option for their favorite snacks to feed their guilty pleasures. Aside from serving Marikeños with a more affordable alternative for their meryenda, Dropitea Marikina aims to provide working spaces for students and professionals as it provides free internet connectivity to its customers. This branch wants to cultivate an environment where friends and families can hangout enjoying great food and great customer service. \n" +
            "\n" +
            "Apart from serving food and beverage at their store, this branch partnered with several online delivery platforms to enable customers to order their favorites and be delivered at their doorsteps. Dropitea Marikina is proud with its relationship with its baristas, rider-partners, and all other stakeholders to make this enterprise a success. This brand is not afraid to innovate as to what its customers want as it’s responsive to their demands. Dropitea Marikina is excited and humble to serve all Marikeños to the best that they can.\n"
      }
    ],
    silver: [
      {
        name: "Rustan Coffee Corporation",
        logo: "https://i.ibb.co/hxNwMsh/rustan-min.png"
      },
      {
        name: "6MA Office Supplies Trading",
        logo: "https://i.ibb.co/2k6w208/6-MA-Logo-modified-min.png"
      }
    ],
    media: [
      {
        name: "Philippine Daily Inquirer",
        website: ["https://www.inquirer.net/"],
        logo: "https://i.ibb.co/nk0KZCq/pdi-min.jpg"
      }
    ],
    orgs: [
      {
        name: "The Palladium",
        avp: "https://drive.google.com/file/d/1Jp7e23ulSMl_OCtmkSLtcHQd6VYfnMMi/preview",
        logo: "https://i.ibb.co/bNj1Gyk/Palladium-Colored-Logo-min.png",
        writeup: "The Palladium is the Official Student Newspaper of the Ateneo School of Law. Committed to responsible journalism inside and beyond the law school, this student publication is dedicated to reporting relevant news inside the campus, as well as beyond. Joining The Palladium entails a meaningful yet enjoyable time out from the books, cases and recitation as the Publication seeks to inform the Law School Community."
      }
    ]
  }},
  methods: {
    launchModal(sponsor) {
      this.sponsorModalContent = sponsor
      this.isSponsorModalActive = true
    },
    onModalClose() {
      this.sponsorModalContent = null
    }
  }
}
</script>

<style scoped>
.heading {
  margin-bottom: 12px;
}

.logo {
  cursor: pointer;
}

.glitter, .pearl {
  max-width: 220px;
  max-height: 180px;
}

.gold {
  max-height: 154px;
}

.silver {
  width: 92px;
}

.media, .orgs {
  width: 78px;
}
</style>