<template>
  <div>
    <div class="main columns is-centered has-background-primary-dark">
      <div class="column is-8-desktop is-10-tablet about-text">
        <h1 class="title is-1 has-text-white">About the Event</h1>

        <p class="heading is-size-4 has-text-gold">HIRATI: THE 2023 ATENEO LAW SCHOOL CAREER FAIR</p>
        <p class="has-text-white">This year’s Career Fair takes inspiration from the Filipino term “hirati” which means accustomed or acquainted. Being a batch that began their law school journey in an online set-up, and eventually transitioned to on-site classes, our efforts to adapt to the inevitable changes that come with learning have been an avenue for us to thrive. </p>

        <p class="heading is-size-4 has-text-gold">THE EVENT</p>
        <p class="has-text-white">The Career Fair is an annual event held at the Ateneo Law School that enables law students to prepare not just for the apprenticeship program but also for future employment. The event is organized by the Sophomores with the guidance of the student council, school administration, and alumni association. It aims to equip the students with the necessary skills and tools during the application process and  connect them with law firms and organizations they may work with in the future.</p>
      </div>
    </div>

    <section class="main has-text-centered has-background-ghost-white">
      <h1 class="title">Featured firms</h1>
      <FirmLogoWall></FirmLogoWall>
    </section>
    <section class="main has-text-centered">
      <sponsors-list></sponsors-list>
    </section>

    <div class="faqs has-background-primary-dark">
      <h1 class="title is-1 has-text-gold">Frequently Asked Questions (FAQs)</h1>
      <faqs-list></faqs-list>
    </div>
  </div>
</template>

<script>
import SponsorsList from "../components/SponsorsList";
import FaqsList from "../components/FaqsList";
import FirmLogoWall from "../components/FirmLogoWall";
export default {
  name: "About",
  components: {FirmLogoWall, FaqsList, SponsorsList},
  title: "About | Hirati: Ateneo Law School Career Fair 2023"
}
</script>

<style scoped>
.main, .faqs {
  padding: 6.9%;
}

.about-text>p {
  margin-bottom: 16px;
}

.heading {
  margin-top: 28px !important;
}
</style>